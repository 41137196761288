import React from 'react';
import PropTypes from 'prop-types';
import TabLink from '../../../elements/Link/TabLink';
import styles from './styles.module.css';
import albukharyLogo from '../../../static/images/general/albukhary.svg';

const SponsorLink = ({ className, ...props }) => (
  <div className={`${styles.link} ${className}`} {...props}>
    <TabLink href="http://albukharyfoundation.my/">
      <img alt="Albukhary foundation logo" src={albukharyLogo} />
    </TabLink>
  </div>
);

SponsorLink.defaultProps = {
  className: '',
};

SponsorLink.propTypes = {
  className: PropTypes.string,
};

export default SponsorLink;
